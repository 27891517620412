import numeral from 'numeral'
import moment from 'moment'

export const isNull = (val) => {
  if (val === undefined || val === null || val === '') return true
  else return false
}

export const toInt = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0')
}

export const toDigi = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0,0')
}

export const precision = (val, multiple = 100) => {
  return Math.floor(val * multiple) / multiple
}

export const toMoney = (val) => {
  if (isNull(val) || isNaN(+val)) return '0'
  else return numeral(val).format('0,0.00')
}

export const toText = (val) => {
  if (isNull(val)) return '-'
  else return val
}

const getMonth = (isShort, isThai) => {
  if (isShort) {
    return isThai ? SHORT_TH_MONTH : SHORT_MONTH
  } else {
    return isThai ? TH_MONTH : MONTH
  }
}

export const toDate = (val, { format, isShort = true, isThai } = {}) => {
  if (isNull(val)) return '-'

  const date = moment(val, format)
  const m = date.month()
  const y = date.year()

  const month = getMonth(isShort, isThai)
  const year = isThai ? y + 543 : y
  return `${date.date()} ${month[m]} ${year}`
}

export const toTime = (val, { format } = {}) => {
  if (isNull(val)) return '-'
  else return moment(val, format).format('HH:mm')
}

export const toDatetime = (val, { format, isShort = true, isThai } = {}) => {
  if (isNull(val)) return '-'

  const date = toDate(val, { format, isShort, isThai })
  const t = toTime(val, { format })

  return `${date} ${t}`
}

export const toExportDay = (val) => {
  return val ? moment(val).format('DD/MM/YYYY') : ''
}

export const toExportDate = (val) => {
  return val ? moment(val).format('DD/MM/YYYY HH:mm') : ''
}

export const toMonth = (val, { format, isShort, isThai } = {}) => {
  if (isNull(val)) return ''

  const date = moment(val, format)
  const m = date.month()
  const y = date.year()

  const month = getMonth(isShort, isThai)
  return `${month[m]} ${y}`
}

export const toNameDate = (val, txt) => {
  if (isNull(val)) return '-'
  else return moment(val, txt).format('DD MMM YYYY')
}

export const toWeekDate = (val) => {
  if (isNull(val)) return '-'
  else return moment(val).format('dddd')
}

export const upperText = (text = '') => {
  if (text.length === 0) return text
  return text[0].toUpperCase() + text.substring(1)
}

export const toPattern = (value, pattern) => {
  if (!value) return ''

  let valueIndex = 0
  const list = String(pattern).split('')
  const newList = []

  for (let i = 0; i < pattern.length && valueIndex < value.length; i++) {
    if (list[i] === 'x') {
      newList.push(value[valueIndex++])
    } else {
      newList.push(list[i])
    }
  }

  return newList.join('')
}

export const clearPattern = (text = '', ch = '-') => {
  const value = (text || '').replaceAll(ch, '')

  return value
}

export const toMobile = (text) => {
  const mobile = (text || '').replaceAll('-', '')
  return toPattern(mobile, 'xxx-xxx-xxxx')
}

export const replaceAll = (text, ch, to = '') => {
  const val = `${text || ''}`
  const c = `${ch || ''}`

  return val.replaceAll(c, to)
}

export const toUsername = (user = {}, isThai = false) => {
  const name = user.name || ''
  const name_th = user.name_th || ''
  const nickname = user.nickname || ''

  let n = isThai ? name_th : name
  if (n === '') {
    n = isThai ? name : name_th
  }
  return nickname === '' ? n : `${n} (${nickname})`
}

export const getWorkTime = (val = 0) => {
  if (val <= 0) return { hour: 0, minute: 0 }
  const hour = Math.trunc(val / 60)
  const minute = Math.trunc(val % 60)

  return { hour, minute }
}

export const toWorkTime = (val = 0, { isThai }) => {
  const h = isThai ? 'ชั่วโมง' : 'hrs'
  const m = isThai ? 'นาที' : 'mins'

  if (val <= 0) return `0 ${m}`

  const { hour, minute } = getWorkTime(val)
  const hTxt = `${hour} ${h}`
  const tTxt = `${minute} ${m}`
  if (hour > 0 && minute > 0) {
    return `${hTxt} ${tTxt}`
  }

  if (hour > 0) return hTxt
  return tTxt
}

const SHORT_MONTH = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

const SHORT_TH_MONTH = {
  0: 'ม.ค.',
  1: 'ก.พ.',
  2: 'มี.ค.',
  3: 'ม.ย.',
  4: 'พ.ค.',
  5: 'มิ.ย.',
  6: 'ก.ค.',
  7: 'ส.ค.',
  8: 'ก.ย.',
  9: 'ต.ค.',
  10: 'พ.ย.',
  11: 'ธ.ค.',
}

const MONTH = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
}

const TH_MONTH = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}
