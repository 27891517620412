import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/account`

const TitleName = {
  mr: 'mr',
  นาย: 'mr',
  mrs: 'mrs',
  ms: 'ms',
  นาง: 'mrs',
  miss: 'miss',
  นางสาว: 'miss',
}

const infoList = [
  { name: 'manager_employee_id', value: 'manager employee id', type: 'text' },
  { name: 'employee_id', value: 'employee id', type: 'text' },
  { name: 'title_name', value: 'title name', type: 'text' },
  { name: 'name', value: 'name', type: 'text' },
  { name: 'surname', value: 'surname', type: 'text' },
  { name: 'name_th', value: 'name (th)', type: 'text' },
  { name: 'surname_th', value: 'surname (th)', type: 'text' },
  { name: 'nickname', value: 'nickname', type: 'text' },

  { name: 'country_code', value: 'country code', type: 'text', def: '+66' },
  { name: 'email', value: 'email', type: 'text' },
  { name: 'mobile', value: 'mobile', type: 'text' },
  { name: 'birthday_date', value: 'birthday date', type: 'date' },
  { name: 'employee_type', value: 'employee type', type: 'text' },
  { name: 'join_company_at', value: 'join company date', type: 'date' },
  { name: 'department_code', value: 'department code', type: 'text' },
  { name: 'position_code', value: 'position code', type: 'text' },
]

const privacyList = [
  { name: 'salary', value: 'salary', type: 'int' },
  { name: 'provident_fund', value: 'provident fund (%)', type: 'int' },
  { name: 'investment_fund', value: 'investment fund', type: 'int' },
  { name: 'lift_insurance', value: 'life insurance', type: 'int' },
  { name: 'tax_deduction_other', value: 'other tax deduction', type: 'int' },
  { name: 'foreigner', value: 'foreigner', type: 'text' },
  { name: 'citizen_id', value: 'citizen id', type: 'text' },
  { name: 'passport_no', value: 'passport no', type: 'text' },
  { name: 'social_security_no', value: 'social security no', type: 'text' },
]

const addressList = [
  { name: 'street', value: 'address', type: 'text' },
  { name: 'tumbon', value: 'sub district', type: 'text' },
  { name: 'district', value: 'district', type: 'text' },
  { name: 'province', value: 'province', type: 'text' },
  { name: 'postcode', value: 'postcode', type: 'text' },
]

const bankList = [
  { name: 'bank_code', value: 'bank code', type: 'text' },
  { name: 'account_no', value: 'account no', type: 'text' },
  { name: 'account_name', value: 'account name', type: 'text' },
]

const toItem = (item = {}, nameList = []) => {
  const data = {}

  for (const it of nameList) {
    const { name, value, type } = it
    const def = it.def || ''
    const val = item[value] || def
    switch (type) {
      case 'int':
        data[name] = +val || 0
        break
      case 'date':
        data[name] = val !== '' ? timer.get(val, 'DD/MM/YYYY') : undefined
        break
      default:
        data[name] = val
        break
    }
  }

  data.mobile = (data.mobile || '').replaceAll('-', '')
  return data
}

const getBankCode = (c, bank_list = []) => {
  const code = (c || '').toUpperCase()

  const bank = bank_list.find((it) => it.short_name === code)

  return bank ? bank.bank_code : ''
}

const getDepartment = (
  { department_code, position_code },
  department_list = []
) => {
  const doc = department_list.find((it) => it.code === department_code)
  const department = doc || {}

  const { department_id, name } = department
  const position_list = department.position_list || []
  const position = position_list.find((it) => it.code === position_code)

  const position_name = position ? position.name : ''
  return { department_id, department_name: name, position_name }
}

const toEmployee = (
  item = {},
  { bank_list = [], department_list = [], benefit_list = [] } = {}
) => {
  const information = toItem(item, infoList)
  information.title_name = TitleName[information.title_name] || 'mr'

  const privacy = toItem(item, privacyList)
  const address = toItem(item, addressList)
  const bank = toItem(item, bankList)

  const emergency_contact = item['emergency contact'] || ''
  const emergency_list = emergency_contact.split(',')

  bank.bank_code = getBankCode(bank.bank_code, bank_list)
  const { department_id, department_name, position_name } = getDepartment(
    information,
    department_list
  )

  information.department_id = department_id
  information.department_name = department_name
  information.position = position_name

  const contact_list = []
  let contact = {}
  for (const it of emergency_list) {
    if (contact.contact_name === undefined) {
      contact.contact_name = it
    } else {
      contact.mobile = it
      contact_list.push(contact)
      contact = {}
    }
  }

  const keys = Object.keys(item)
  const benefitList = []

  for (const it of benefit_list) {
    const { benefit_id } = it
    const codeTag = `(${it.code})`
    const name = keys.find((n) => n.includes(codeTag))
    if (name) {
      const val = +item[name] || 0
      benefitList.push({
        benefit_id,
        name: it.name,
        name_th: it.name_th,
        amount: val,
      })
    }
  }

  return {
    employee_id: information.employee_id,
    information,
    privacy,
    bank,
    address,
    benefit: { benefit_list: benefitList },
    emergency_contact: { contact_list },
  }
}

let state
export class Importer extends BaseStore {
  constructor() {
    super()
    this.observable({
      employee: {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      },
    })
    state = this
  }

  resetEmployee() {
    runInAction(() => {
      state.employee = {
        result: {
          success: 0,
          error: 0,
        },
        list: [],
        page: {
          index: 1,
          per_page: 20,
          total: 0,
        },
      }
    })
  }

  setPage({ index, per_page, total }) {
    runInAction(() => {
      state.employee.page = {
        index,
        per_page,
        total,
      }
    })
  }

  loadFileWorkingData(list = []) {
    runInAction(() => {
      state.employee = {
        result: {
          success: 0,
          error: 0,
        },
        list,
        page: {
          index: 1,
          per_page: 20,
          total: list.length,
        },
      }
    })
  }

  async importEmployee(data = {}) {
    try {
      const { employee_id } = data

      if (!employee_id) {
        data.status = 'error'
        data.message = 'no employee id'
        return data
      }
      const url = `${Url}/importer/employee-id/${employee_id}`

      await http.post(url, { json: data })

      data.status = 'success'
    } catch (e) {
      data.status = 'error'
      data.message = e.message
    }

    return data
  }

  async updateEmployee(data = {}) {
    try {
      const { employee_id } = data

      if (!employee_id) {
        data.status = 'error'
        data.message = 'no employee id'
        return data
      }
      const url = `${Url}/importer/employee-id/${employee_id}/update`

      await http.put(url, { json: data })

      data.status = 'success'
    } catch (e) {
      data.status = 'error'
      data.message = e.message
    }

    return data
  }

  setResult(result_list = []) {
    const result = { success: 0, error: 0 }
    for (const item of result_list) {
      const { status } = item
      if (status === 'success') result.success++
      else result.error++
    }

    runInAction(() => {
      state.employee = {
        result,
        list: result_list,
        page: {
          index: 1,
          per_page: 20,
          total: result_list.length,
        },
      }
    })
  }

  async importEmployeeList(list = [], option = {}) {
    const { is_send_invite } = option
    let result_list = []

    for (const item of list) {
      const { status } = item
      if (status !== 'success') {
        item.is_send_invite = is_send_invite
        const res = await this.importEmployee(item)
        result_list.push(res)
      } else {
        result_list.push(item)
      }
    }

    this.setResult(result_list)
  }

  async updateAllEmployeeList(list = []) {
    let result_list = []
    for (const item of list) {
      const { status, message } = item
      if (status !== 'success' && message === 'employee id is duplicated') {
        const res = await this.updateEmployee(item)
        result_list.push(res)
      } else {
        result_list.push(item)
      }
    }

    this.setResult(result_list)
  }

  async updateImportEmployee(item = {}) {
    const res = await this.updateEmployee(item)

    const { list = [] } = this.toJS().employee
    const index = list.findIndex((it) => {
      return it.employee_id === item.employee_id
    })

    if (index > -1) {
      list[index] = res
      this.setResult(list)
    }
  }

  loadFileEmployeeData(
    list = [],
    { department_list, benefit_list, bank_list } = {}
  ) {
    const result_list = []
    for (const it of list) {
      const item = toEmployee(it, {
        department_list,
        benefit_list,
        bank_list,
      })

      result_list.push(item)
    }

    runInAction(() => {
      state.employee = {
        result: {
          success: 0,
          error: 0,
        },
        list: result_list,
        page: {
          index: 1,
          per_page: 20,
          total: result_list.length,
        },
      }
    })
  }
}

export default new Importer()
