import { runInAction } from 'mobx'

import { http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/attendance/working-employee`

let state
export class Employee extends BaseStore {
  constructor() {
    super()
    this.observable({
      date: timer.get(),
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      date,
      employee_type,
      office_id,
      working_time_id,
      user_id,
      department_id,
      project_id,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (date) {
      const start_date = date.clone().startOf('month').toISOString()
      const end_date = date.clone().endOf('month').toISOString()

      q += `&start_date=${start_date}&end_date=${end_date}`
    }
    if (office_id) q += `&office_id=${office_id}`
    if (working_time_id) q += `&working_time_id=${working_time_id}`
    if (working_time_id) q += `&working_time_id=${working_time_id}`
    if (user_id) q += `&user_id=${user_id}`
    if (employee_type) q += `&employee_type=${employee_type}`
    if (department_id) q += `&department_id=${department_id}`
    if (project_id) q += `&project_id=${project_id}`

    return q
  }

  async getExportList(query) {
    const q = this.toQuery({ ...query, per_page: -1 })
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    return list
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.date = query.date
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  setShiftList({ work_at, work_list }, shift_list = [], newWorkList = []) {
    const date = work_at.toISOString()
    const dateTxt = work_at.format('DDMMYY')
    const index = shift_list.findIndex((it) => {
      return it.date === dateTxt
    })

    if (index === -1) {
      shift_list.push({ work_at: date, date: dateTxt, work_list })
    } else {
      shift_list[index].work_list = newWorkList
    }
  }

  processShiftList(json = {}, newWorkList = []) {
    const { user_id, work_at, work_list, option } = json
    const { list } = this.toJS()
    const i = list.findIndex((it) => it.user_id === user_id)

    if (i !== -1) {
      const userWorking = list[i]
      const shift_list = userWorking.shift_list || []

      if (option === 'none') {
        this.setShiftList({ work_at, work_list }, shift_list, newWorkList)
      } else {
        const endOfMonth = work_at.clone().endOf('months')
        const num = endOfMonth.diff(work_at, 'days')

        let start = work_at.clone()
        for (let i = 0; i <= num; i++) {
          this.setShiftList(
            { work_at: start, work_list },
            shift_list,
            newWorkList
          )

          start = start.add(1, 'days')
        }
      }

      userWorking.shift_list = shift_list
      list[i] = userWorking
      runInAction(() => {
        state.list = list
      })
    }
  }

  async updateWorkingEmployee(json = {}) {
    const { user_id, work_at, work_list } = json

    const newWorkList = []
    const workingTime = {}
    for (const item of work_list) {
      const working_time_id = +item.working_time_id || null
      const office_id = +item.office_id || null

      if (!working_time_id && !office_id) continue
      if (workingTime[working_time_id]) continue

      workingTime[working_time_id] = true
      newWorkList.push(item)
    }

    const date = work_at.toISOString()
    json.work_list = newWorkList
    const url = `${Url}/${user_id}/${date}`
    await http.put(url, { json })

    this.processShiftList(json, newWorkList)
  }
}

export default new Employee()
